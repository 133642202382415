"use strict";
function whyAos() {
    if($(window).width()<768) {
        $('.we__list .item').attr('data-aos','fade-up')
    }
    $(window).on('resize', function () {
        if($(window).width()<768) {
            $('.we__list .item').attr('data-aos','fade-up')
        } else {
            $('.we__list .item').eq(0).attr('data-aos','zoom-out-right');
            $('.we__list .item').eq(1).attr('data-aos','zoom-out-down');
            $('.we__list .item').eq(2).attr('data-aos','zoom-out-left');
            $('.we__list .item').eq(3).attr('data-aos','zoom-out-right');
            $('.we__list .item').eq(4).attr('data-aos','zoom-out-left');
        }
    });
}

function popup(popup) {
    $(popup).appendTo($('.fade'));
    $('html').css('overflow', 'hidden');
    $('.fade').addClass('is-active');
    $(popup).find('.popup__close').on('click', popupClose);
}

function popupClose(e) {
    $('.fade').find('.popup').appendTo($('body'));
    $('html').css('overflow', '');
    $('.fade').removeClass('is-active');
    setTimeout(function () {
        $('.popup__form').show();
        $('.popup__thanks').hide()
    },500);
    e.preventDefault()
}

function headerFixed () {
    const header = $('.header__container');
    if($(window).scrollTop()>$('.header').height()) {
        header.addClass('fixed')
    }
    $(window).on('scroll', function () {
        //if($(window).scrollTop()>$('.header').height()) {
        if($(window).scrollTop()>0) {
            header.addClass('fixed')
        } else {
            header.removeClass('fixed')
        }
    })
}

$(document).ready(function() {
    whyAos();
    AOS.init();

    var $topNavElements = $('nav a');
    $('.section').scrollspy({
        outCallback: function ($element) {
            $topNavElements.filter('[href="#' + $element.attr('id') + '"]').removeClass('active');
        },
        inCallback:  function ($element, side) {
            $topNavElements.filter('[href="#' + $element.attr('id') + '"]').addClass('active');
        }
    });

    $("nav a").click(function (e){
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top - $('.header__container').outerHeight()
        }, 1000);
        if($(window).width()<768) {
            $('.hamburger').removeClass('is-active');
            $('.menu').removeClass('is-active')
        }
        e.preventDefault()
    });

    //$('.why .item__parallax').parallax({imageSrc: '/theme/images/section_why.jpg'});
    //$('.why .item__image').eq(0).parallax({imageSrc: '/theme/images/section_why.jpg'});
    /*if($(window).width()<=360) {
        $('.we .item__parallax').parallax({imageSrc: '/theme/images/section_we@360.jpg'});
        $('.opportunities .item__parallax').parallax({imageSrc: '/theme/images/section_opportunities@360.jpg'});
        $('.reviews .item__parallax').parallax({imageSrc: '/theme/images/section_reviews@360.jpg'});
        $('.news .item__parallax').parallax({imageSrc: '/theme/images/section_news@360.jpg'});
    }*/
    //else if ($(window).width()>360 && $(window).width()<=480) {
    /*if ($(window).width()<=480) {
        $('.we .item__parallax').parallax({imageSrc: '/theme/images/section_we@480.jpg'});
        $('.opportunities .item__parallax').parallax({imageSrc: '/theme/images/section_opportunities@480.jpg'});
        $('.reviews .item__parallax').parallax({imageSrc: '/theme/images/section_reviews@480.jpg'});
        $('.news .item__parallax').parallax({imageSrc: '/theme/images/section_news@480.jpg'});
    }*/
    //else if ($(window).width()<768 && $(window).width()>480) {
    if ($(window).width()<768) {
        $('.we .item__parallax').parallax({imageSrc: '/theme/images/section_we@770.jpg'});
        $('.opportunities .item__parallax').parallax({imageSrc: '/theme/images/section_opportunities@770.jpg'});
        $('.reviews .item__parallax').parallax({imageSrc: '/theme/images/section_reviews@770.jpg'});
        $('.news .item__parallax').parallax({imageSrc: '/theme/images/section_news@770.jpg'});
    } else {
        $('.we .item__parallax').parallax({imageSrc: '/theme/images/section_we.jpg'});
        $('.opportunities .item__parallax').parallax({imageSrc: '/theme/images/section_opportunities.jpg'});
        $('.reviews .item__parallax').parallax({imageSrc: '/theme/images/section_reviews.jpg'});
        $('.news .item__parallax').parallax({imageSrc: '/theme/images/section_news.jpg'});
    }


    /*$('.why .item__slider-list').on('afterChange', function(event, slick, currentSlide, nextSlide){
        //console.log(nextSlide);
        setTimeout(function () {
            $(slick.$slides.get(currentSlide)).find('.item__text').removeClass('aos-animate');
        },1)

        //$(slick.$slides.get(currentSlide)).find('.item__text').removeAttr('data-aos');

        console.log($(slick.$slides.get(currentSlide)));
        //slick
    });
    $('.why .item__slider-list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        //$(slick.$slides.get(nextSlide)).find('.item__text').attr('data-aos','zoom-in-down');
        setTimeout(function () {
            $(slick.$slides.get(nextSlide)).find('.item__text').addClass('aos-animate');
        },1)

        console.log($(slick.$slides.get(currentSlide)));
        //slick
    });*/


    $('.why .item__slider-list').slick({
        //adaptiveHeight: true,
        autoplay: true,
        speed: 500,
        fade: true,
        prevArrow: $('.slick__left'),
        nextArrow: $('.slick__right'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: false,
                    autoplay: true,
                    cssEase: 'ease-in-out',
                    //fade: false
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.clients__list').slick({
        autoplay: true,
        variableWidth: true,
        infinite: true,
        centerMode: true,
        arrows: false,
        speed: 2000
    });

    $('.reviews__list ul').slick({
        //autoplay: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        prevArrow: $('.slick-left'),
        nextArrow: $('.slick-right'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    infinite: true,
                    speed: 500,
                    autoplaySpeed: 2000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });

    $('.hamburger').on('click', function () {
       $(this).toggleClass('is-active');
       $('.menu').toggleClass('is-active')
    });
    headerFixed();




});

$(document).on('af_complete', function(event, response) {
    var form = response.form;
    // Если у формы определённый id
    console.log(response.success);
    if (form.attr('id') === 'feedback__form' && response.success) {
        // Скрываем её!
        //form.hide();
        //popupClose('#order')
        $('.popup__form').hide();
        $('.popup__thanks').fadeIn()

    }
    // Иначе печатаем в консоль весь ответ
    else {
        console.log(response)
    }
});